:root {
	/* ------- colors ------- */
	--primary-color: #1EA896;
	--secondary-color: #539987;
	--tertiary-color: #D8DBE2;
	--quaternary-color: #D8DBE2;
	--link-color: #94778B;
	--background-color: #303633;
	/* ---------------------- */

	/* ------- fonts ------- */
	--primary-font: "Heebo", sans-serif;
	--secondary-font: "Roboto", sans-serif;
	/* --------------------- */
}
